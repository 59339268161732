export const getAuth = () =>
  JSON.parse(localStorage.getItem('alleypin:auth') || null);

export const setAuth = ({ token, liffId, userId }) => {
  const authData = {
    ...(token && { Authorization: `Bearer ${token}` }),
    'x-liff-id': liffId,
    'x-lineuser-id': userId,
  };
  localStorage.setItem('alleypin:auth', JSON.stringify(authData));
};

export const removeAuth = () => {
  localStorage.removeItem('alleypin:auth');
};

export const getVendor = (token) => {
  try {
    const strings = token.split('.');
    const tokenInfo = JSON.parse(
      decodeURIComponent(
        window.atob(strings[1].replace(/-/g, '+').replace(/_/g, '/'))
      )
    );
    return tokenInfo.data?.vendor || tokenInfo.data?._vendor;
  } catch (e) {
    alert(`token parse error: ${e}`);
  }
};

export const isOpenApi = () => {
  try {
    return [
      'https://clinic.alyp.in',
      'https://clinic-staging.alyp.in',
    ].includes(window.location.origin);
  } catch (e) {
    return false;
  }
};

export const getApiUrl = () => {
  /* @NOTE:
    開發時，如果要測試 aomori 的 api，直接 return 對應的 clinic, 例如 'https://ktop.alyp.in'
    測試 aomori 診所時，isOpenApi return false & getApiUrl return 'https://ktop.alyp.in'
    測試 open api 時，isOpenApi return true & getApiUrl return process.env.OPEN_API_URL
  */
  return isOpenApi() ? process.env.OPEN_API_URL : 'https://techgroup.alyp.in';
};

export const setCookie = (name, value, minutes) => {
  let expires = '';
  if (minutes) {
    const date = new Date();
    date.setTime(date.getTime() + minutes * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value || ''}${expires}; path=/`;
};
